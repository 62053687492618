import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";

export interface ISite {
    id: number;
    regionID: number;
    description: string;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
}

export class Site implements ISite {

    constructor(data?: ISite) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISite) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    regionID: number = 0;
    description: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }

    static emptySiteOption(description: string): ISite {
        return {
            id: 0,
            regionID: 0,
            description: description,
            created: new Date(utils.emptyDateValue),
            createdByUserID: 0,
            lastUpdated: new Date(utils.emptyDateValue),
            lastUpdatedByUserID: 0,
            deleted: new Date(utils.emptyDateValue),
            deletedByUserID: 0
        };
    }
}