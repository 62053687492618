import { mapData } from "@/stuff/DataMapping";
import { TriState } from "./Enums";

export interface ISupplierSearchParameters {
    pageNumber: number;
    searchText: string;
    isDeleted: TriState;
    regionID: number;
    siteID: number;
    buyerID: number;
    tradeID: number;
    isDormant: TriState;
    amFollowing: boolean;
    isBuyerZone: boolean;
    isContractor: boolean;
    isConsultant: boolean;
    fullyComplaintID: number;
    supplierChar: string;
    showBuyerDormantSuppliers: boolean;
}

export class SupplierSearchParameters implements ISupplierSearchParameters {

    constructor(data?: ISupplierSearchParameters) {
        if (data) this.update(data);
    }

    update(data: ISupplierSearchParameters) {
        mapData(data, { root: () => this });
    }

    pageNumber: number = 0;
    searchText: string = "";
    isDeleted: TriState = TriState.UseDefault;
    regionID: number = 0;
    siteID: number = 0;
    buyerID: number = 0;
    tradeID: number = 0;
    isDormant: TriState = TriState.UseDefault;
    amFollowing: boolean = false;
    isBuyerZone: boolean = false;
    isContractor: boolean = false;
    isConsultant: boolean = false;
    fullyComplaintID: number = -1;
    supplierChar: string = "";
    showBuyerDormantSuppliers: boolean = false;

    get showDeleted(): boolean {
        return this.isDeleted !== TriState.False;
    }
    set showDeleted(value: boolean) {
        this.isDeleted = value ? TriState.UseDefault : TriState.False;
    }

}